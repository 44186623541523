import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api-service'
import { Title } from '@angular/platform-browser';

@Injectable()
export class TenantService {
    tenant: any
    token: any

    allowed_roles = ['operatore','manager','admin']

    constructor(
        private apiService: ApiService,
        private title: Title


    ) {
      
     this.getTenant().then((ok:any)=>{
     	this.title.setTitle("IDENTIFICATO.ONLINE - " + this.tenant.ragione_sociale)
     })
    }

    setToken(token:any){
        this.token = token;
        const roles = token.realm_access.roles;
        token.role = this.getAppRole(roles)
      
    }


    getAppRole(roles:string[]){
        for(let i = 0;i<this.allowed_roles.length;i++){
               if(roles.indexOf(this.allowed_roles[i]) > -1) return this.allowed_roles[i]  
        }
       
        return ''
    }


    getTenant():any{
    	
    	return new Promise((resolve,reject) =>{
    		if(this.tenant) resolve(this.tenant)
    		else {
    			this.apiService.myTenant().toPromise().then((tenant:any) =>{
    				this.tenant = tenant;
    				resolve(this.tenant)
    			})
    		}
    	})
    }


  

}