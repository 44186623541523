import { AuthConfig,OAuthService,JwksValidationHandler } from 'angular-oauth2-oidc';
import {CanActivate ,ActivatedRouteSnapshot,RouterStateSnapshot,UrlTree,Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import {Injectable} from '@angular/core'
import { TenantService } from 'src/app/services/tenant-service'
import { environment} from "src/environments/environment"


const defaultRoutes:any = {
  operatore: 'identifica/session/manage',
  admin: 'backoffice/admin',
  manager: 'backoffice/manager',
}






@Injectable()
export class AuthorizeRoute implements CanActivate {
    authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
   

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.href.split('?')[0],

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
   

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',

    responseType: 'code',

    requireHttps: false,

    //oidc: false,

    strictDiscoveryDocumentValidation: false,
    useSilentRefresh: true,
    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: '',

   
  };
  constructor(private oauthService: OAuthService, private tenantService: TenantService,private router: Router){
      
       
 
  }

  setToken(route: ActivatedRouteSnapshot){
      const token = this.oauthService.getAccessToken() as any;
             const claims = JSON.parse(atob(token.split('.')[1]))
             console.log(claims)
         this.tenantService.setToken(claims)
         if(this.tenantService.token.role !== route.data['role']){    
        
          if(defaultRoutes[this.tenantService.token.role]){
             this.router.navigateByUrl(defaultRoutes[this.tenantService.token.role])
            
          } else   this.router.navigateByUrl('login')
        
         }



  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
   
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
      return this.tenantService.getTenant().then((tenant:any)=>{
        
        this.authCodeFlowConfig.clientId = 'identificato.online.dashboard';
        this.authCodeFlowConfig.issuer = environment.authUrl + '/realms/' +tenant.client_id;        
        this.oauthService.configure(this.authCodeFlowConfig);
    //  if (!this.oauthService.hasValidAccessToken()) {
      if(!this.tenantService.token )
       return this.oauthService.loadDiscoveryDocumentAndLogin().then((k:boolean) =>{
          
         
           if(k){
           this.setToken(route)
       return true
       
       }
       return true

     });
  /*   } else  {
       this.setToken(route)
     }*/
        
     

     


          
       

        return true
      })
        
  }
}