import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TemplateBlocco } from '../backoffice/entity/TemplateBlocco';
import { Operatore } from '../backoffice/entity/Operatore';
import { Tenant_Entity } from '../backoffice/entity/TenantEntity';
import { environment} from "src/environments/environment"
@Injectable()
export class ApiService {
    host: string



    constructor(
        private http: HttpClient


    ) {
        
        this.host = environment.apiUrl

    

    }

    public acceptPrivacy(uid:string,privacy_type:string){
        let data = {privacy_type: privacy_type, privacy_consent: true}
         return this.http.post<any>(this.host + '/session/privacy/' + uid,data).toPromise()
    }

     public enqueueSession(uid:string){
      
        let data = {dummy_value: true}
         return this.http.post<any>(this.host + '/session/enqueue/' + uid,data).toPromise()
    }


    public myTenant(){
       return this.http.get<any>(this.host + '/tenant/me').pipe(
            map((result) => result)
        ) 
    }


    public sessioneAgganciata(){
          return this.http.get<any>(this.host + '/session/agganciata').toPromise()
    }


    public retrieve(name: string, id: string) {
        return this.http.get<object>(this.host + '/' + name + '/' + id).pipe(
            map((result) => result)
        )
    }

    public list(name: string, filters?: any) {
        let params = new HttpParams()
        if (filters) {
            for (let key in filters) {
                params.set(key, filters[key])
            }
        }
        return this.http.get<Object>(this.host + '/' + name).pipe(
            map((result) => result)
        )
    }

    public delete(name: string, id: string) {
        return this.http.delete<any>(this.host + '/' + name + '/' + id).pipe(
            map((result) => result)
        )
    }

    public requestSession(data: any) {
        return this.http.post<any>(this.host + '/session/',data).pipe(
            map((result) => result)
        )
    }

    public acceptSession(session: string) {
     
        return this.http.post<any>(this.host + '/session/accept/' +session, {}).toPromise()
    }

    public getUploadedPhoto(session: string,photo_id: string){
         return this.http.get<any>(this.host + '/foto/' +session + "/"+ photo_id).toPromise()
    }

    public uploadPhoto(session: string,data: any){
         return this.http.post<any>(this.host + '/foto/' +session , data).toPromise()
    }



    public createSession(session_id: string) {
        return this.http.post<any>(this.host + '/session/join/' +session_id, {dummy_value:true}).toPromise()
    }
    

    public commitPhase(data: any, uid: string,additional_params: any) {
        let query_string = '?'
        let sep = ''
        for(let k in additional_params){
           query_string += sep + k + "=" + additional_params[k]
        }
        console.log(data)
        if(!data) data = {};
        data.dummy_value = true;

        return this.http.post<object>(this.host + '/processiverificapassi/' + uid + '/next' +query_string,data).toPromise()


    }

    public verifySession(session: string) {


        return this.http.post<Object>(this.host + '/session/verifica/' + session, {}).toPromise()

    }

    public existsSession(session: string) {


        return this.http.get<Object>(this.host + '/session/' + session, {}).toPromise()

    }

    public attesaSession(session: string) {


        return this.http.get<Object>(this.host + '/session/' + session).toPromise()

    }

    public sendOtp(type:string,session_id: string){
         return this.http.get<any>(this.host + '/otp/' + type +'/' + session_id).toPromise()
    }

    public getSessions() {


        return this.http.get<any[]>(this.host + '/session/attesa').toPromise()

    }
    public getAllStats() {
        return this.http.get<any[]>(this.host + '/stats/summary').toPromise()
    }
    public getStatsCompleted() {
        return this.http.get<any[]>(this.host + '/stats/summary/completed').toPromise()
    }
    public getStatsFailed() {
        return this.http.get<any[]>(this.host + '/stats/summary/failed').toPromise()
    }
    public getRichieste() {
        return this.http.get<Object>(this.host + '/richieste/').toPromise()
    }
    public getNazioni() {
        return this.http.get<Object>(this.host + '/nazioni/').toPromise()
    }
    public getRegioni(id: number) {
        // return this.http.get<Object>(this.host + '/regioni?nazione="nazione_id"/').toPromise()
        return this.http.get<Object>(`${this.host}/regioni?nazione_id=${id}`).toPromise()
    }
    public getProvince(id: number) {
        // return this.http.get<Object>(this.host + '/regioni?nazione="nazione_id"/').toPromise()
        return this.http.get<Object>(`${this.host}/province?nazione_id=${id}`).toPromise()
    }
    public getTemplateBlocchi() {
        return this.http.get<TemplateBlocco[]>(this.host + '/templateblocchi/').toPromise()
    }
    public getSteps() {
        return this.http.get<TemplateBlocco[]>(this.host + '/templateblocchi/default').toPromise()
    }
    public saveTemplateBlocchi(blocco: TemplateBlocco) {
        return this.http.post<any>(`${this.host}/templateblocchi/${blocco.id}`, blocco).toPromise()
    }
    public saveConfig(tenant: Tenant_Entity) {
        return this.http.post<any>(`${this.host}/tenant/` + tenant.id, tenant).toPromise()
    }
    public saveNewOperatore(operatore: Operatore) {
        return this.http.post<any>(`${this.host}/utenti/`, operatore).toPromise()
    }
    public saveOperatore(operatore: Operatore) {
        return this.http.post<any>(`${this.host}/utenti/${operatore.id}`, operatore).toPromise()
    }

    public getRichiesteAttive() {
        return this.http.get<any>(this.host + '/richieste?stato="attive"/').toPromise()
    }
    public getOperatori() {
        return this.http.get<any>(this.host + '/operatori?ruolo_id=2').toPromise()
    }
    public getOperatoriPresenti() {
        return this.http.get<any>(this.host + '/utenti/presenti').toPromise()
        // return this.http.get<Object>(this.host + '/utentipresenze/').toPromise()
    }
    public getUtentiById(id: number) {
        return this.http.get<any>(`${this.host}/utenti/${id}/`).toPromise()
    }
    public getSessioniAttivi() {
        return this.http.get<any>(this.host + '/session/attivi/').toPromise()
    }
    public getTenant() {
        return this.http.get<any>(this.host + '/tenant/').toPromise()
    }
     public getMyself() {
        return this.http.get<any>(this.host + '/utenti/me').toPromise()
    }
    public saveTenant(tenant: Tenant_Entity) {
        //return this.http.get<Object>(this.host + '/tenant/').toPromise()
        return this.http.post<any>(`${this.host}/tenant/${tenant.id}`, tenant).toPromise()
    }
    public newTenant(tenant: Tenant_Entity) {
        return this.http.post<any>(`${this.host}/tenant`, tenant).toPromise()
    }
    public getTenantById(id: number) {
        return this.getTenantById$(id).toPromise()
    }
    public getMe() {
        return this.http.get<Object>(this.host + '/me/').toPromise()
    }
    public getTenantById$(id: number) {
        return this.http.get<any>(`${this.host}/tenant/${id}/`)
    }
    public getOperatoriById(id: number) {
        return this.getTenantById$(id).toPromise()
    }
    public getOperatoriById$(id: number) {
        return this.http.get<any>(`${this.host}/operatori/${id}/`)
    }

    public getTenant1() {
        return this.http.get<Object>(this.host + '/tenant/1').toPromise()
    }

    public acceptUser(session_id: string) {


        return this.http.post<any>(this.host + '/session/avvio/' + session_id, {}).toPromise()

    }

    public rejectSession(session_id: string, error_code: number) {
        return this.http.post<string>(this.host + '/session/rifiuta/' + session_id, { error_code: error_code }).toPromise()
    }


    public getPassiProcessoVerifica(id: number) {
        return this.http.get<any>(this.host + '/processiverificapassi/?processo_id=' + id).toPromise()
    }

    private trueP() {
        return new Promise((resolve, reject) => {
            resolve(true)
        })
    }


    public startRecording(session_id: string) {
        return this.http.post<any>(this.host + '/webrtc/recording/' + session_id + '/start', {})
    }


    public stopRecording(recording_id: string) {
        return this.http.post<any>(this.host + '/webrtc/recording/' + recording_id + '/stop', {})
    }

    public getToken(session_id: string) {
        return this.http.post<any>(this.host + '/webrtc/token/' + session_id, {})
    }

  



}