import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeRoute } from "src/app/services/auth-guard";
import { AppComponent } from './app.component';
const routes: Routes = [

         
       
  		
		{
            path: 'backoffice',
            loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule),
           //canActivate: [AuthorizeRoute],
        },

        {
            path: 'public',
            loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
           
        },

        {
            path: 'identifica',
            loadChildren: () => import('./identifica/identifica.module').then(m => m.IdentificaModule),
           
           
        },

       
            {
                path: 'login',
                component: AppComponent,
                 canActivate: [AuthorizeRoute],
                
            },
  		

 
            {
                path: '**',
                redirectTo: 'login',
                
            },
        
    
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
