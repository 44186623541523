import { NgModule ,ErrorHandler} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { NgToastModule } from 'ng-angular-popup'
import { AppComponent } from './app.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';


import { WebrtcService } from 'src/app/services/webrtc.service'
import { ApiService } from 'src/app/services/api-service'
import { TenantService } from 'src/app/services/tenant-service'

import { AuthorizeRoute } from 'src/app/services/auth-guard'



import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateFnsModule } from 'ngx-date-fns';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import * as Sentry from "@sentry/angular";


@NgModule({
  declarations: [
    AppComponent,

    SidebarComponent,






  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgToastModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ["https://localhost:44308","https://sandbox.identificato.online","https://api.identificato.online"],
        sendAccessToken: true
    }

    }),
    AppRoutingModule,
    DateFnsModule.forRoot(),
    /*ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),*/


  ],
  providers: [
  {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    ApiService, TenantService, AuthorizeRoute, Title,WebrtcService],
  bootstrap: [AppComponent]
})
export class AppModule { }
