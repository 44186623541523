import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api-service'
import { OpenVidu, Publisher, Session, StreamEvent, StreamManager, Subscriber } from 'openvidu-browser';
import { environment} from "src/environments/environment"




@Injectable()
export class WebrtcService {
  OV?: OpenVidu;
  OVsession?: Session;
  publisher?: Publisher; // Local
  subscribers?: StreamManager[] = []; // Remotes
  sessionId?: string
  token?: string


  mainStreamManager?: StreamManager;

  constructor(private apiService: ApiService){
         
 
  }

  async disconnect(){
      if(this.OVsession){
        if(this.publisher){
          await this.OVsession.unpublish(this.publisher)
        await this.OVsession.disconnect()
        }
        
        
      else await this.OVsession.disconnect()
      
      }
    return true
  }

  registerEvent(fn: (event: any) => void){
      if(this.OVsession){
          this.OVsession.on('signal',fn)
      }
  }

  registerReconnectedEvent(fn: (event:any)=>void){
    if(this.OVsession){
          this.OVsession.on('reconnected',fn)
      }
  }




  getDevices(){
    return this.OV.getDevices()
  }

  isFrontCamera: boolean = true
  async toggleCamera(){


    const devices = await this.getDevices()

        // Getting only the video devices
    const videoDevices = devices.filter(device => device.kind === 'videoinput');

    if (videoDevices && videoDevices.length > 1){
      console.log(videoDevices)
            this.isFrontCamera = !this.isFrontCamera;
            // Creating a new publisher with specific videoSource
            // In mobile devices the default and first camera is the front one

            const stream = await this.OV.getUserMedia({
                videoSource: this.isFrontCamera ? videoDevices[0].deviceId : videoDevices[1].deviceId,
               audioSource: false
               
            })

            try {
               this.publisher.replaceTrack(stream.getVideoTracks()[0]);
            } catch(error:any){
              if(error.name != 'DEVICE_ACCESS_DENIED') return this.publisher
               const newPublisher = await this.OV.initPublisherAsync(undefined, {
                videoSource:    this.isFrontCamera ? videoDevices[0].deviceId : videoDevices[1].deviceId,
                publishAudio: true,
                publishVideo: true,
                mirror: this.isFrontCamera, // Setting mirror enable if front camera is selected
                resolution: '1208x720',  // The resolution of your video
                frameRate: 30,          // The frame rate of your video
                insertMode: 'APPEND',
            });

            // Changing isFrontCamera value
            

            // Unpublishing the old publisher
      await  this.OVsession.unpublish(this.publisher)
              

                // Assigning the new publisher to our global variable 'publisher'
                this.publisher = newPublisher;

                // Publishing the new publisher
      await this.OVsession.publish(this.publisher)
            }


           
      return this.publisher
        
  
  } else return this.publisher
}
  


  private registerStreamEvents(){
    // On every new Stream received...
      this.OVsession.on('streamCreated', (event: StreamEvent) => {
        console.log(event)
          // Subscribe to the Stream to receive it. Second parameter is undefined
          // so OpenVidu doesn't create an HTML video by its own
          let subscriber: Subscriber | undefined = this.OVsession?.subscribe(event.stream, undefined);

          if(this.subscribers.length) this.subscribers = []
         
          this.subscribers.push(subscriber);
    });



    // On every Stream destroyed...
    this.OVsession.on('streamDestroyed', (event: StreamEvent) => {
      console.log(event)

      // Remove the stream from 'subscribers' array
      this.deleteSubscriber(event.stream.streamManager);
    });

    // On every asynchronous exception...
    this.OVsession.on('exception', (exception) => {
      console.warn(exception);
    });

  }

  private async connectToSession(user:string){
    try{
      await  this.OVsession.connect(this.token, { clientData: user })

    } catch(ex){
      throw {
        error_code: 'session_connection',
        error_message: 'Impossibile connettersi alla sessione'
      }
    }

  }


  private async initPublisher(isScreen:boolean):Promise<void>{
    try {
       this.publisher = await this.OV.initPublisherAsync(undefined, {
            audioSource: undefined, // The source of audio. If undefined default microphone
            videoSource:  isScreen? "screen" :undefined, // The source of video. If undefined default webcam
            publishAudio: true,     // Whether you want to start publishing with your audio unmuted or not
            publishVideo: true,     // Whether you want to start publishing with your video enabled or not          
            resolution: '1208x720',  // The resolution of your video
            frameRate: 30,          // The frame rate of your video
            insertMode: 'APPEND',   // How the video is inserted in the target element 'video-container'
            mirror: !isScreen           // Whether to mirror your local video or not
      });

       this.publisher.on('streamDestroyed',(event: StreamEvent)=>{
        console.log('STREAMDESTROYED',event)
        if(event?.reason == 'networkDisconnect'){
          console.log("Network disconnect")
          //this.joinSession(this.sessionId,this.user,this.isScreen)
          
        
        }
       })

      

    } catch(ex){

      throw {
        error_code: 'video_src',
        error_message: 'Impossibile inizializzare la webcam'
      }


    }
  }


  connection:any


  async republish(isScreen:boolean = false){
    await this.OVsession.unpublish(this.publisher)

    await this.initPublisher(isScreen)

    if(!isScreen)
    await this.OVsession.publish(this.publisher);

    return this.publisher

  }

  streamDestroyed?: ()=>void
  networkDiscconnect?:(publisher:Publisher)=>void
  user?: string;
  isScreen: boolean = false;

  async joinSession(sessionId: string,user: string,isScreen:boolean = false):Promise<Publisher> {
    try {

        this.subscribers = []
     
        this.user = user;
        this.isScreen = isScreen
             // --- 1) Get an OpenVidu object ---
      this.sessionId = sessionId;
      this.OV = new OpenVidu();
      this.OV.setAdvancedConfiguration({
        forceMediaReconnectionAfterNetworkDrop: true,
        noStreamPlayingEventExceptionTimeout: 8000,
        iceConnectionDisconnectedExceptionTimeout: 8000

      })
      
      if(environment.production)
          this.OV.enableProdMode()
    // --- 2) Init a session ---
    
      this.OVsession = await this.OV.initSession();

     



    this.OVsession.on('streamCreated', (event: StreamEvent) => {
       console.log('streamEvent',event)
       if(event.stream.connection.data && JSON.parse(event.stream.connection.data).clientData != this.user){
          
          // Subscribe to the Stream to receive it. Second parameter is undefined
          // so OpenVidu doesn't create an HTML video by its own
          let subscriber: Subscriber | undefined = this.OVsession?.subscribe(event.stream, undefined);
          console.log('created',subscriber)
         if(this.subscribers.length) this.subscribers = []
          this.subscribers.push(subscriber);
      }
    });

    



    // On every Stream destroyed...
    this.OVsession.on('streamDestroyed', (event: StreamEvent) => {
      console.log("EVENTONE", event)
      if(event.reason == 'sessionClosedByServer' && this.streamDestroyed)
      this.streamDestroyed()
      //this.deleteSubscriber(event.stream.streamManager);
    });

    // On every asynchronous exception...
    this.OVsession.on('exception', (exception) => {
      
      //this.joinSession(this.sessionId,this.user,this.isScreen)
      console.warn(exception);
    });

    

   
    // --- 4) Connect to the session with a valid user token ---

    // 'getToken' method is simulating what your server-side should do.
    // 'token' parameter should be retrieved and returned by your own backend
    await this.getToken( this.sessionId)

    await this.connectToSession(user)

          // --- 5) Get your own camera stream ---
          
          // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
          // element: we will manage it on our own) and with the desired properties
   

          // --- 6) Publish your stream ---

    await this.initPublisher(isScreen)

    await this.OVsession.publish(this.publisher);

    


     return this.publisher

          // Set the main video in the page to display our webcam and store our Publisher
          
         
      
    } catch(ex){
      console.warn(ex)
      throw ex
    }
  

       
  }


  getToken(sessionId: string): Promise<string> {
    return this.createSession(sessionId).then(
      (session_data:any) => {
          this.token= session_data['token']
           return session_data
      },(error:any) =>{
          throw error;
          
      })
  }

  




  async createSession(sessionId: string) {
    try {
      return await this.apiService.createSession(sessionId)

    } catch(error){
      throw error
    }

      
   
  }

  sendSessionMessage(message:string[]){
    return this.sendMessage("session:" + this.sessionId +":" + message.join(":"))
  }


  sendMessage(message: string,type:string='event') {

    return this.OVsession.signal({
      data: message,  // Any string (optional)
      to: [],                     // Array of Connection objects (optional. Broadcast to everyone if empty)
      type: type             // The type of message (optional)
    })
      .then(() => {
        console.log('Message successfully sent');
      })
      .catch(error => {
        throw error;
        
      });


  }

   private deleteSubscriber(streamManager: StreamManager): void {
    let index = this.subscribers.indexOf(streamManager, 0);
   // this.OVsession.unsubscribe(this.subscribers[index])
    if (index > -1) {
      this.subscribers.splice(index, 1);
    }
  }


  

}