import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {ApiService} from 'src/app/services/api-service'


 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
 constructor(private httpClient: HttpClient){
      	

  }

  }


